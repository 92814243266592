import obmed from "@/services/obmed";
import { ActionTree, GetterTree, ModuleTree, MutationTree } from "vuex";

import { RootState } from "@/store/types";
import {
  ContactState,
  ContactLocaleState,
  ContactLocaleMutations,
  ContactLocaleActions,
  ContactLocaleGetters,
  ContactEmail,
  ContactWhapsApp,
} from "@/store/contact/types";

const { VUE_APP_API_URL } = process.env;

const endpoints = {
  email: "enviar-mensagem-por-email/",
  whatsapp: "enviar-mensagem-por-whatsapp/",
  guideEmail: "enviar-segunda-via-da-guia-eletronica/",
};

const state: ContactLocaleState = {};

const getters: GetterTree<ContactState, RootState> & ContactLocaleGetters = {};

const mutations: MutationTree<ContactState> & ContactLocaleMutations = {};

const actions: ActionTree<ContactState, RootState> & ContactLocaleActions = {
  async sendMessageByWhatsApp({ commit, dispatch }, { message, numbers, cd_horario_agendamento }) {
    try {
      const response = await obmed.post<any, ContactWhapsApp>({
        entity: endpoints.whatsapp,
        data: { message, numbers, cd_horario_agendamento },
        config: { baseURL: VUE_APP_API_URL },
      });

      if (response?.status === 200)
        commit("addToast", {
          summary: `${numbers.length > 1 ? "Mensagens" : "Mensagem"} via WhatsApp ${
            numbers.length > 1 ? "enviadas" : "enviada"
          } com sucesso!`,
          severity: "success",
        });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async sendMessageByEmail({ commit, dispatch }, { message, emails, cd_horario_agendamento }) {
    try {
      const response = await obmed.post<any, ContactEmail>({
        entity: endpoints.email,
        data: { message, emails, cd_horario_agendamento },
        config: { baseURL: VUE_APP_API_URL },
      });

      if (response?.status === 200)
        commit("addToast", {
          summary: `${emails.length > 1 ? "Mensagens" : "Mensagem"} via E-mail ${
            emails.length > 1 ? "enviadas" : "enviada"
          } com sucesso!`,
          severity: "success",
        });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },

  async sendGuideEmail({ commit, dispatch }, { cd_horario_agendamento }) {
    try {
      const response = await obmed.post<any>({
        entity: endpoints.guideEmail,
        data: { cd_horario_agendamento },
        config: { baseURL: VUE_APP_API_URL },
      });

      if (response?.status === 200)
        commit("addToast", {
          summary: "Guia enviada com sucesso!",
          severity: "success",
        });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
};

const modules: ModuleTree<RootState> = {};

export const ContactStore = { state, getters, modules, mutations, actions };
