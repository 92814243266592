import obmed from "@/services/obmed";
import { ActionTree, GetterTree, ModuleTree, MutationTree } from "vuex";

import { RootState } from "@/store/types";
import {
  Specialist,
  SpecialistState,
  SpecialistLocaleState,
  SpecialistLocaleMutations,
  SpecialistLocaleActions,
  SpecialistLocaleGetters,
  SpecialistForm,
} from "@/store/specialist/types";
import { PaginationParams, PaginationResponse } from "../types";

const { VUE_APP_API_URL } = process.env;

const endpoints = {
  root: "especialista/",
  byID: (_id: number | string) => `especialista/${_id}/`,
  link: "especialista/associar-usuario/",
};

const state: SpecialistLocaleState = {
  current: null,
  list: [],
  searchList: [],
  pagination: { page: 1, total: 1, items: 0, perPage: 25 },
};

const getters: GetterTree<SpecialistState, RootState> & SpecialistLocaleGetters = {};

const mutations: MutationTree<SpecialistState> & SpecialistLocaleMutations = {
  setSpecialist(state, specialist) {
    state.current = specialist;
  },
  setSpecialists(state, specialists) {
    state.list = specialists;
  },
  setSearchSpecialists(state, employees) {
    state.searchList = employees;
  },
  setSpecialistPagination(state, pagination) {
    state.pagination = pagination;
  },
  setSpecialistPaginationPage(state, { page }) {
    state.pagination.page = page;
  },
};

const actions: ActionTree<SpecialistState, RootState> & SpecialistLocaleActions = {
  async getSpecialist({ commit, dispatch }, { _id }) {
    try {
      const response = await obmed.get<Specialist>({
        entity: endpoints.byID(_id),
        config: { baseURL: VUE_APP_API_URL },
      });
      commit("setSpecialist", response.data);

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async getSpecialists({ state, commit, dispatch }, { config } = { config: {} }) {
    const pagination: PaginationParams = {
      page: state.pagination.page,
      per_page: state.pagination.perPage,
      paginacao: true,
    };
    try {
      const response = await obmed.get<PaginationResponse<Specialist>>({
        entity: endpoints.root,
        config: { query: { ...pagination }, baseURL: VUE_APP_API_URL, ...config },
      });

      commit("setSpecialists", response.data?.results || []);
      commit("setSpecialistPagination", { ...state.pagination, items: response.data.count });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async createSpecialist({ commit, dispatch }, { form }) {
    try {
      if (!form.cd_estabelecimento) form.cd_estabelecimento = Number(localStorage.getItem("CD_ESTABELECIMENTO") || 0);

      const response = await obmed.post<any, SpecialistForm>({
        entity: endpoints.root,
        data: form,
        config: { baseURL: VUE_APP_API_URL },
      });

      if (response?.status === 201)
        commit("addToast", {
          summary: "Cadastro realizado com sucesso!",
          severity: "success",
        });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async updateSpecialist({ commit, dispatch }, { _id, form }) {
    try {
      const response = await obmed.patch<any, SpecialistForm>({
        entity: endpoints.byID(_id),
        data: form,
        config: { baseURL: VUE_APP_API_URL },
      });

      if (response?.status === 200)
        commit("addToast", {
          summary: "Dados atualizados com sucesso!",
          severity: "success",
        });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async deleteSpecialist({ commit, dispatch }, { _id }) {
    try {
      const response = await obmed.delete({
        entity: endpoints.byID(_id),
        config: { baseURL: VUE_APP_API_URL },
      });

      if (response?.status === 200)
        commit("addToast", {
          summary: "Especialista removido com sucesso!",
          severity: "success",
        });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async updateSpecialistPhoto({ dispatch }, { _id, aq_foto }) {
    try {
      const form = new FormData();
      form.append("aq_foto", aq_foto, aq_foto.name || "especialista");

      const response = await obmed.patch<any>({
        entity: endpoints.byID(_id),
        data: form,
        config: { baseURL: VUE_APP_API_URL },
      });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async linkSpecialist({ commit, dispatch }, { form }) {
    try {
      const response = await obmed.post<any>({
        entity: endpoints.link,
        data: form,
        config: { baseURL: VUE_APP_API_URL },
      });
      if (response?.status === 201)
        commit("addToast", {
          summary: "Especialista vinculado com sucesso!",
          severity: "success",
        });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async unlinkSpecialist({ commit, dispatch }, { form }) {
    try {
      const response = await obmed.delete<any>({
        entity: endpoints.link,
        data: form,
        config: { baseURL: VUE_APP_API_URL },
      });
      if (response?.status === 200)
        commit("addToast", {
          summary: "Especialista desvinculado com sucesso!",
          severity: "success",
        });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async searchSpecialists({ state, commit, dispatch }, { search, isPrimary, config }) {
    try {
      const pagination: PaginationParams = { page: 1, per_page: 25, paginacao: true };
      const response = await obmed.get<PaginationResponse<Specialist>>({
        entity: endpoints.root,
        config: { query: { ...search, ...pagination, ...(config || {}) }, baseURL: VUE_APP_API_URL },
      });

      commit("setSearchSpecialists", response.data?.results || []);

      if (isPrimary) {
        commit("setSpecialists", response.data?.results || []);
        commit("setSpecialistPagination", { ...state.pagination, items: response.data.count });
      }

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
};

const modules: ModuleTree<RootState> = {};

export const SpecialistStore = { state, getters, modules, mutations, actions };
