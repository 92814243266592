import obmed from "@/services/obmed";
import { ActionTree, GetterTree, ModuleTree, MutationTree } from "vuex";

import { RootState } from "@/store/types";
import {
  Specialty,
  SpecialtyState,
  SpecialtyLocaleState,
  SpecialtyLocaleMutations,
  SpecialtyLocaleActions,
  SpecialtyLocaleGetters,
} from "@/store/specialty/types";
import { PaginationParams, PaginationResponse } from "../types";

const { VUE_APP_API_URL } = process.env;

const endpoints = {
  root: "especialidade/",
  byID: (_id: number | string) => `especialidade/${_id}/`,
};

const state: SpecialtyLocaleState = {
  current: null,
  establishment: [],
  list: [],
  all: [],
  pagination: { page: 1, total: 1, items: 0, perPage: 25 },
};

const getters: GetterTree<SpecialtyState, RootState> & SpecialtyLocaleGetters = {};

const mutations: MutationTree<SpecialtyState> & SpecialtyLocaleMutations = {
  setSpecialty(state, specialty) {
    state.current = specialty;
  },
  setSpecialties(state, specialties) {
    state.list = specialties;
  },
  setAllSpecialty(state, specialties) {
    state.all = specialties;
  },
  setSpecialtyPagination(state, pagination) {
    state.pagination = pagination;
  },
  setSpecialtyPaginationPage(state, { page }) {
    state.pagination.page = page;
  },
};

const actions: ActionTree<SpecialtyState, RootState> & SpecialtyLocaleActions = {
  async getSpecialty({ commit, dispatch }, { _id }) {
    try {
      const response = await obmed.get<Specialty>({
        entity: endpoints.byID(_id),
        config: { baseURL: VUE_APP_API_URL, useCompany: false },
      });
      commit("setSpecialty", response.data);

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async getSpecialties({ state, commit, dispatch }) {
    try {
      const pagination: PaginationParams = {
        page: state.pagination.page,
        per_page: state.pagination.perPage,
        paginacao: true,
      };
      const response = await obmed.get<PaginationResponse<Specialty>>({
        entity: endpoints.root,
        config: { query: { ...pagination }, baseURL: VUE_APP_API_URL },
      });

      commit("setSpecialties", response.data?.results || []);
      commit("setSpecialtyPagination", { ...state.pagination, items: response.data.count });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async getAllSpecialty({ commit, dispatch }) {
    try {
      const response = await obmed.get<Specialty[]>({
        entity: endpoints.root,
        config: { baseURL: VUE_APP_API_URL },
      });

      commit("setAllSpecialty", response.data || []);

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async createSpecialty({ commit, dispatch }, { form }) {
    try {
      const formData = new FormData();
      Object.keys(form).forEach((key) => {
        formData.append(key, (form as any)[key]);
      });

      const response = await obmed.post<any, any>({
        entity: endpoints.root,
        data: formData,
        config: { baseURL: VUE_APP_API_URL, useCompany: false },
      });

      if (response?.status === 201)
        commit("addToast", {
          summary: "Especialidade cadastrada com sucesso!",
          severity: "success",
        });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async updateSpecialty({ commit, dispatch }, { _id, form }) {
    try {
      const formData = new FormData();
      Object.keys(form).forEach((key) => {
        const value = (form as any)[key];
        if (value) formData.append(key, value);
      });

      const response = await obmed.patch<any, any>({
        entity: endpoints.byID(_id),
        data: formData,
        config: { baseURL: VUE_APP_API_URL, useCompany: false },
      });

      if (response?.status === 200)
        commit("addToast", {
          summary: "Especialidade atualizada com sucesso!",
          severity: "success",
        });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async deleteSpecialty({ commit, dispatch }, { _id }) {
    try {
      const response = await obmed.delete({
        entity: endpoints.byID(_id),
        config: { baseURL: VUE_APP_API_URL, useCompany: false },
      });

      if (response?.status === 201)
        commit("addToast", {
          summary: "Especialidade removida com sucesso!",
          severity: "success",
        });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
};

const modules: ModuleTree<RootState> = {};

export const SpecialtyStore = { state, getters, modules, mutations, actions };
