import obmed from "@/services/obmed";
import { ActionTree, GetterTree, ModuleTree, MutationTree } from "vuex";

import { RootState } from "@/store/types";
import {
  Group,
  GroupState,
  GroupLocaleState,
  GroupLocaleMutations,
  GroupLocaleActions,
  GroupLocaleGetters,
  GroupForm,
} from "@/store/group/types";
import { PaginationParams, PaginationResponse } from "../types";

const { VUE_APP_API_URL } = process.env;

const endpoints = {
  root: "grupo/",
  byID: (_id: number | string) => `grupo/${_id}/`,
};

const state: GroupLocaleState = {
  current: null,
  list: [],
  all: [],
  pagination: { page: 1, total: 1, items: 0, perPage: 25 },
};

const getters: GetterTree<GroupState, RootState> & GroupLocaleGetters = {};

const mutations: MutationTree<GroupState> & GroupLocaleMutations = {
  setGroup(state, group) {
    state.current = group;
  },
  setGroups(state, groups) {
    state.list = groups;
  },
  setAllGroups(state, groups) {
    state.all = groups;
  },
  setGroupPagination(state, pagination) {
    state.pagination = pagination;
  },
  setGroupPaginationPage(state, { page }) {
    state.pagination.page = page;
  },
};

const actions: ActionTree<GroupState, RootState> & GroupLocaleActions = {
  async getGroup({ commit, dispatch }, { _id }) {
    try {
      const response = await obmed.get<Group>({
        entity: endpoints.byID(_id),
        config: { baseURL: VUE_APP_API_URL, useCompany: false },
      });
      commit("setGroup", response.data);

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async getGroups({ state, commit, dispatch }) {
    const pagination: PaginationParams = {
      page: state.pagination.page,
      per_page: state.pagination.perPage,
      paginacao: true,
    };
    try {
      const response = await obmed.get<PaginationResponse<Group>>({
        entity: endpoints.root,
        config: { query: { ...pagination }, baseURL: VUE_APP_API_URL, useCompany: false },
      });

      commit("setGroups", response.data?.results || []);
      commit("setGroupPagination", { ...state.pagination, items: response.data.count });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async getAllGroups({ commit, dispatch }) {
    try {
      const response = await obmed.get<Group[]>({
        entity: endpoints.root,
        config: { baseURL: VUE_APP_API_URL, useCompany: false },
      });

      commit("setAllGroups", response.data || []);

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async createGroup({ commit, dispatch }, { form }) {
    try {
      const response = await obmed.post<any, GroupForm>({
        entity: endpoints.root,
        data: form,
        config: { baseURL: VUE_APP_API_URL, useCompany: false },
      });

      if (response?.status === 201)
        commit("addToast", {
          summary: "Grupo cadastrada com sucesso!",
          severity: "success",
        });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async updateGroup({ commit, dispatch }, { _id, form }) {
    try {
      const response = await obmed.patch<any, GroupForm>({
        entity: endpoints.byID(_id),
        data: form,
        config: { baseURL: VUE_APP_API_URL, useCompany: false },
      });

      if (response?.status === 200)
        commit("addToast", {
          summary: "Grupo atualizada com sucesso!",
          severity: "success",
        });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async deleteGroup({ commit, dispatch }, { _id }) {
    try {
      const response = await obmed.delete({
        entity: endpoints.byID(_id),
        config: { baseURL: VUE_APP_API_URL, useCompany: false },
      });

      if (response?.status === 201)
        commit("addToast", {
          summary: "Grupo removida com sucesso!",
          severity: "success",
        });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
};

const modules: ModuleTree<RootState> = {};

export const GroupStore = { state, getters, modules, mutations, actions };
