import obmed, { ObmedCancelRequest } from "@/services/obmed";
import { ActionTree, GetterTree, ModuleTree, MutationTree } from "vuex";

import { RootState } from "@/store/types";
import {
  Procedure,
  ProcedureState,
  ProcedureLocaleState,
  ProcedureLocaleMutations,
  ProcedureLocaleActions,
  ProcedureLocaleGetters,
  ProcedureV2,
  ProcedureForm,
  ProcedureType,
} from "@/store/procedure/types";
import { PaginationParams, PaginationResponse } from "../types";

const { VUE_APP_API_URL } = process.env;

const endpoints = {
  root: "procedimento/",
  byID: (_id: number | string) => `procedimento/${_id}/`,
  type: "tipo-procedimento/",
};

const cancelRequests: { [key: string]: ObmedCancelRequest | null } = {
  search: null,
};

const state: ProcedureLocaleState = {
  current: null,
  list: [],
  allV2: [],
  types: [],
  searchList: [],
  establishment: [],
  pagination: { page: 1, total: 1, items: 0, perPage: 25 },
};

const getters: GetterTree<ProcedureState, RootState> & ProcedureLocaleGetters = {};

const mutations: MutationTree<ProcedureState> & ProcedureLocaleMutations = {
  setProcedure(state, procedure) {
    state.current = procedure;
  },
  setProcedures(state, procedures) {
    state.list = procedures;
  },
  setProcedureTypes(state, procedureTypes) {
    state.types = procedureTypes;
  },
  setSearchProcedures(state, procedures) {
    state.searchList = procedures;
  },
  setAllProceduresV2(state, procedures) {
    state.allV2 = procedures;
  },
  setProcedurePagination(state, pagination) {
    state.pagination = pagination;
  },
  setProcedurePaginationPage(state, { page }) {
    state.pagination.page = page;
  },
};

const actions: ActionTree<ProcedureState, RootState> & ProcedureLocaleActions = {
  async getProcedure({ commit, dispatch }, { _id }) {
    try {
      const response = await obmed.get<Procedure>({
        entity: endpoints.byID(_id),
        config: { baseURL: VUE_APP_API_URL },
      });
      commit("setProcedure", response.data);

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async getProcedures({ state, commit, dispatch }) {
    const pagination: PaginationParams = {
      page: state.pagination.page,
      per_page: state.pagination.perPage,
      paginacao: true,
    };
    try {
      const response = await obmed.get<PaginationResponse<Procedure>>({
        entity: endpoints.root,
        config: { query: { ...pagination }, baseURL: VUE_APP_API_URL },
      });

      commit("setProcedures", response.data?.results || []);
      commit("setProcedurePagination", { ...state.pagination, items: response.data.count });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async createProcedure({ commit, dispatch }, { form }) {
    try {
      const response = await obmed.post<any, ProcedureForm>({
        entity: endpoints.root,
        data: form,
        config: { baseURL: VUE_APP_API_URL },
      });

      if (response?.status === 201)
        commit("addToast", {
          summary: "Cadastro realizado com sucesso!",
          severity: "success",
        });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async updateProcedure({ commit, dispatch }, { _id, form }) {
    try {
      const response = await obmed.patch<any, ProcedureForm>({
        entity: endpoints.byID(_id),
        data: form,
        config: { baseURL: VUE_APP_API_URL },
      });

      if (response?.status === 201)
        commit("addToast", {
          summary: "Procedimento atualizado com sucesso!",
          severity: "success",
        });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async deleteProcedure({ commit, dispatch }, { _id }) {
    try {
      const response = await obmed.delete({
        entity: endpoints.byID(_id),
        config: { baseURL: VUE_APP_API_URL },
      });

      if (response?.status === 200)
        commit("addToast", {
          summary: "Procedimento removido com sucesso!",
          severity: "success",
        });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async getProcedureTypes({ commit, dispatch }) {
    try {
      const response = await obmed.get<ProcedureType[]>({
        entity: endpoints.type,
        config: { baseURL: VUE_APP_API_URL },
      });

      commit("setProcedureTypes", response.data || []);

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async getAllProceduresV2({ commit, dispatch }) {
    try {
      const response = await obmed.get<ProcedureV2[]>({
        entity: endpoints.root,
        config: { baseURL: VUE_APP_API_URL },
      });

      commit("setAllProceduresV2", response.data || []);

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async searchProcedures({ commit, dispatch }, { search, isPrimary, hasPagination = true }) {
    try {
      if (cancelRequests.search) cancelRequests.search.cancel();
      cancelRequests.search = obmed.getCancelToken();

      const pagination: PaginationParams = { page: 1, per_page: 100, paginacao: hasPagination };
      const response = await obmed.get<PaginationResponse<Procedure> | Procedure[]>({
        entity: endpoints.root,
        config: {
          query: { ...search, ...(hasPagination ? pagination : {}) },
          cancelToken: cancelRequests.searchAgendamento?.token,
          baseURL: VUE_APP_API_URL,
        },
      });

      const procedures: Procedure[] = (response.data as any)?.results || response.data || [];
      commit("setSearchProcedures", procedures);

      if (isPrimary) {
        commit("setProcedures", procedures);
        commit("setProcedurePagination", { ...state.pagination, items: (response.data as any)?.count });
      }

      return procedures;
    } catch (error) {
      dispatch("handleError", error);
      return [];
    }
  },
};

const modules: ModuleTree<RootState> = {};

export const ProcedureStore = { state, getters, modules, mutations, actions };
