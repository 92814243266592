import axios, { AxiosInstance } from "axios";

import formatObmedError from "@/services/obmed/errors";
import { addQuery, configRequestDefs } from "@/services/obmed/utils";

import { ObmedRequestConfig } from "@/services/obmed/types";

export function configResponse(instance: AxiosInstance) {
  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error?.isAxiosError && error.response?.status === 404 && error.config?.params?.paginacao) {
        const newConfig = { ...error.config };
        newConfig.params.page = 1;

        return axios.request(newConfig);
      } else {
        return Promise.reject(await formatObmedError(error));
      }
    }
  );
}

export function configRequest(instance: AxiosInstance) {
  instance.interceptors.request.use(
    (config: ObmedRequestConfig) => {
      if (config.query) return addQuery(configRequestDefs(config));
      return configRequestDefs(config);
    },
    (error) => {
      const DEBUG = process.env.NODE_ENV === "development";
      if (DEBUG) console.error(error);

      return Promise.reject(error);
    }
  );
}

export function configInterceptors(instance: AxiosInstance) {
  configRequest(instance);
  configResponse(instance);

  return instance;
}

export default configInterceptors;
