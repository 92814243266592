import obmed from "@/services/obmed";
import { ActionTree, GetterTree, ModuleTree, MutationTree } from "vuex";

import { RootState } from "@/store/types";
import {
  CashierMovement,
  CashierMovementState,
  CashierMovementLocaleState,
  CashierMovementLocaleMutations,
  CashierMovementLocaleActions,
  CashierMovementLocaleGetters,
} from "@/store/cashier/movement/types";
import { PaginationParams, PaginationResponse } from "@/store/types";

const { VUE_APP_API_URL } = process.env;

const endpoints = {
  root: "transacao-financeira/movimentos-caixa/",
};

const state: CashierMovementLocaleState = {
  list: [],
  searchList: [],
  pagination: { page: 1, total: 1, items: 0, perPage: 25 },
};

const getters: GetterTree<CashierMovementState, RootState> & CashierMovementLocaleGetters = {};

const mutations: MutationTree<CashierMovementState> & CashierMovementLocaleMutations = {
  setCashierMovements(state, movements) {
    state.list = movements;
  },
  setSearchCashierMovements(state, movements) {
    state.searchList = movements;
  },
  setCashierMovementPagination(state, pagination) {
    state.pagination = pagination;
  },
  setCashierMovementPaginationPage(state, { page }) {
    state.pagination.page = page;
  },
};

const actions: ActionTree<CashierMovementState, RootState> & CashierMovementLocaleActions = {
  async getCashierMovements({ state, commit, dispatch }) {
    const pagination: PaginationParams = {
      page: state.pagination.page,
      per_page: state.pagination.perPage,
      paginacao: true,
    };
    try {
      const response = await obmed.get<PaginationResponse<CashierMovement>>({
        entity: endpoints.root,
        config: { query: { ...pagination }, baseURL: VUE_APP_API_URL },
      });

      commit("setCashierMovements", response.data?.results || []);
      commit("setCashierMovementPagination", { ...state.pagination, items: response.data.count });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async searchCashierMovements({ state, commit, dispatch }, { search, isPrimary }) {
    try {
      const pagination: PaginationParams = { page: 1, per_page: 25, paginacao: true };
      const response = await obmed.get<PaginationResponse<CashierMovement>>({
        entity: endpoints.root,
        config: { query: { ...search, ...pagination }, baseURL: VUE_APP_API_URL },
      });

      commit("setSearchCashierMovements", response.data?.results || []);

      if (isPrimary) {
        commit("setCashierMovements", response.data?.results || []);
        commit("setCashierMovementPagination", { ...state.pagination, items: response.data.count });
      }

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async getCashierMovementPDF({ dispatch }) {
    try {
      const response = await obmed.get<any>({
        entity: endpoints.root,
        config: { query: { export: "pdf" }, baseURL: VUE_APP_API_URL },
      });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
};

const modules: ModuleTree<RootState> = {};

export const CashierMovementStore = { state, getters, modules, mutations, actions };
