import obmed from "@/services/obmed";
import { ActionTree, GetterTree, ModuleTree, MutationTree } from "vuex";

import { PaginationParams, PaginationResponse, RootState } from "@/store/types";
import {
  VoucherUserLocaleActions,
  VoucherUserLocaleGetters,
  VoucherUserLocaleMutations,
  VoucherUserLocaleState,
  VoucherUserState,
  VoucherUserT,
} from "@/store/voucher/user/types";

const { VUE_APP_API_URL } = process.env;

const endpoints = {
  root: "voucher-user/",
};

const state: VoucherUserLocaleState = {
  list: [],
  all: [],
  pagination: { page: 1, total: 1, items: 0, perPage: 25 },
};

const getters: GetterTree<VoucherUserState, RootState> & VoucherUserLocaleGetters = {};

const mutations: MutationTree<VoucherUserState> & VoucherUserLocaleMutations = {
  setVoucherUsers(state, vouchers) {
    state.list = vouchers;
  },
  setAllVoucherUsers(state, vouchers) {
    state.all = vouchers;
  },
  setVoucherUserPagination(state, pagination) {
    state.pagination = pagination;
  },
  setVoucherUserPaginationPage(state, { page }) {
    state.pagination.page = page;
  },
};

const actions: ActionTree<VoucherUserState, RootState> & VoucherUserLocaleActions = {
  async getVoucherUsers({ state, commit, dispatch }) {
    const pagination: PaginationParams = {
      page: state.pagination.page,
      per_page: state.pagination.perPage,
      paginacao: true,
    };
    try {
      const response = await obmed.get<PaginationResponse<VoucherUserT>>({
        entity: endpoints.root,
        config: { query: { ...pagination }, baseURL: VUE_APP_API_URL },
      });

      commit("setVoucherUsers", response.data?.results || []);
      commit("setVoucherUserPagination", { ...state.pagination, items: response.data.count });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async getAllVoucherUsers({ commit, dispatch }) {
    try {
      const response = await obmed.get<VoucherUserT[]>({
        entity: endpoints.root,
        config: { baseURL: VUE_APP_API_URL },
      });

      commit("setAllVoucherUsers", response.data || []);

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
};

const modules: ModuleTree<RootState> = {};

export const VoucherUserStore = { state, getters, modules, mutations, actions };
