import obmed from "@/services/obmed";
import { ActionTree, GetterTree, ModuleTree, MutationTree } from "vuex";

import { RootState } from "@/store/types";
import {
  CashierTransaction,
  CashierTransactionState,
  CashierTransactionLocaleState,
  CashierTransactionLocaleMutations,
  CashierTransactionLocaleActions,
  CashierTransactionLocaleGetters,
} from "@/store/cashier/transaction/types";
import { PaginationParams, PaginationResponse } from "@/store/types";
import { GuideValidation } from "@/store/guide/types";

const { VUE_APP_API_URL } = process.env;

const endpoints = {
  root: "transacao-financeira/",
  byID: (_id: number | string) => `transacao-financeira/${_id}/`,
  guides: "atendimento/",
};

const state: CashierTransactionLocaleState = {
  list: [],
  searchList: [],
  guides: [],
  pagination: { page: 1, total: 1, items: 0, perPage: 25 },
};

const getters: GetterTree<CashierTransactionState, RootState> & CashierTransactionLocaleGetters = {};

const mutations: MutationTree<CashierTransactionState> & CashierTransactionLocaleMutations = {
  setCashierTransactions(state, transactions) {
    state.list = transactions;
  },
  setSearchCashierTransactions(state, transactions) {
    state.searchList = transactions;
  },
  setCashierTransactionGuides(state, guides) {
    state.guides = guides;
  },
  setCashierTransactionPagination(state, pagination) {
    state.pagination = pagination;
  },
  setCashierTransactionPaginationPage(state, { page }) {
    state.pagination.page = page;
  },
};

const actions: ActionTree<CashierTransactionState, RootState> & CashierTransactionLocaleActions = {
  async getCashierTransactions({ state, commit, dispatch }) {
    const pagination: PaginationParams = {
      page: state.pagination.page,
      per_page: state.pagination.perPage,
      paginacao: true,
    };
    try {
      const response = await obmed.get<PaginationResponse<CashierTransaction>>({
        entity: endpoints.root,
        config: { query: { ...pagination }, baseURL: VUE_APP_API_URL },
      });

      commit("setCashierTransactions", response.data?.results || []);
      commit("setCashierTransactionPagination", { ...state.pagination, items: response.data.count });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async searchCashierTransactions({ state, commit, dispatch }, { search, isPrimary }) {
    try {
      const pagination: PaginationParams = { page: 1, per_page: 25, paginacao: true };
      const response = await obmed.get<PaginationResponse<CashierTransaction>>({
        entity: endpoints.root,
        config: { query: { ...search, ...pagination }, baseURL: VUE_APP_API_URL },
      });

      commit("setSearchCashierTransactions", response.data?.results || []);

      if (isPrimary) {
        commit("setCashierTransactions", response.data?.results || []);
        commit("setCashierTransactionPagination", { ...state.pagination, items: response.data.count });
      }

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async getCashierTransactionGuides({ commit, dispatch }, { guideIds }) {
    try {
      const guides: GuideValidation[] = [];

      await Promise.allSettled(
        guideIds.map(async (guideId) => {
          const response = await obmed.get<GuideValidation[]>({
            entity: endpoints.guides,
            config: { query: { cd_guia_eletronica_id: guideId }, baseURL: VUE_APP_API_URL, useCompany: false },
          });

          if (response.data?.[0]) {
            guides.push(response.data[0]);
          }
        })
      );

      commit("setCashierTransactionGuides", guides);

      return guides;
    } catch (error) {
      dispatch("handleError", error);
      return [];
    }
  },
};

const modules: ModuleTree<RootState> = {};

export const CashierTransactionStore = { state, getters, modules, mutations, actions };
