import obmed from "@/services/obmed";
import { ActionTree, GetterTree, ModuleTree, MutationTree } from "vuex";

import { RootState } from "@/store/types";
import {
  EstablishmentProcedure,
  EstablishmentProcedureState,
  EstablishmentProcedureLocaleState,
  EstablishmentProcedureLocaleMutations,
  EstablishmentProcedureLocaleActions,
  EstablishmentProcedureLocaleGetters,
  EstablishmentProcedureForm,
} from "@/store/establishment/procedure/types";
import { PaginationParams, PaginationResponse } from "../../types";

const { VUE_APP_API_URL } = process.env;

const endpoints = {
  root: "procedimento-estabelecimento/",
  byID: (_id: number | string) => `procedimento-estabelecimento/${_id}/`,
};

const state: EstablishmentProcedureLocaleState = {
  current: null,
  list: [],
  searchList: [],
  pagination: { page: 1, total: 1, items: 0, perPage: 25 },
};

const getters: GetterTree<EstablishmentProcedureState, RootState> & EstablishmentProcedureLocaleGetters = {};

const mutations: MutationTree<EstablishmentProcedureState> & EstablishmentProcedureLocaleMutations = {
  setEstablishmentProcedure(state, procedure) {
    state.current = procedure;
  },
  setEstablishmentProcedures(state, procedures) {
    state.list = procedures;
  },
  setSearchEstablishmentProcedures(state, procedures) {
    state.searchList = procedures;
  },
  setEstablishmentProcedurePagination(state, pagination) {
    state.pagination = pagination;
  },
  setEstablishmentProcedurePaginationPage(state, { page }) {
    state.pagination.page = page;
  },
};

const actions: ActionTree<EstablishmentProcedureState, RootState> & EstablishmentProcedureLocaleActions = {
  async getEstablishmentProcedure({ commit, dispatch }, { _id }) {
    try {
      const response = await obmed.get<EstablishmentProcedure>({
        entity: endpoints.byID(_id),
        config: { baseURL: VUE_APP_API_URL },
      });
      commit("setEstablishmentProcedure", response.data);

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async getEstablishmentProcedures({ state, commit, dispatch }) {
    const pagination: PaginationParams = {
      page: state.pagination.page,
      per_page: state.pagination.perPage,
      paginacao: true,
    };
    try {
      const response = await obmed.get<PaginationResponse<EstablishmentProcedure>>({
        entity: endpoints.root,
        config: { query: { ...pagination }, useCompany: false, baseURL: VUE_APP_API_URL },
      });

      commit("setEstablishmentProcedures", response.data?.results || []);
      commit("setEstablishmentProcedurePagination", { ...state.pagination, items: response.data.count });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async createEstablishmentProcedure({ commit, dispatch }, { form }) {
    try {
      const response = await obmed.post<any, EstablishmentProcedureForm>({
        entity: endpoints.root,
        data: form,
        config: { baseURL: VUE_APP_API_URL },
      });

      if (response?.status === 201)
        commit("addToast", {
          summary: "Cadastro realizado com sucesso!",
          severity: "success",
        });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async updateEstablishmentProcedure({ commit, dispatch }, { _id, form }) {
    try {
      const response = await obmed.patch<any, EstablishmentProcedureForm>({
        entity: endpoints.byID(_id),
        data: form,
        config: { baseURL: VUE_APP_API_URL },
      });

      if (response?.status === 201)
        commit("addToast", {
          summary: "Dados atualizados com sucesso!",
          severity: "success",
        });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async deleteEstablishmentProcedure({ commit, dispatch }, { _id }) {
    try {
      const response = await obmed.delete({
        entity: endpoints.byID(_id),
        config: { baseURL: VUE_APP_API_URL },
      });

      if (response?.status === 200)
        commit("addToast", {
          summary: "Procedimento removido com sucesso!",
          severity: "success",
        });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async searchEstablishmentProcedures({ state, commit, dispatch }, { search, isPrimary, usePagination }) {
    try {
      const pagination: PaginationParams = {
        page: usePagination ? state.pagination.page : 1,
        per_page: usePagination ? state.pagination.perPage : 25,
        paginacao: true,
      };
      const response = await obmed.get<PaginationResponse<EstablishmentProcedure>>({
        entity: endpoints.root,
        config: { query: { ...search, ...pagination }, baseURL: VUE_APP_API_URL },
      });

      commit("setSearchEstablishmentProcedures", response.data?.results || []);

      if (isPrimary) {
        commit("setEstablishmentProcedures", response.data?.results || []);
        commit("setEstablishmentProcedurePagination", { ...state.pagination, items: response.data.count });
      }

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
};

const modules: ModuleTree<RootState> = {};

export const EstablishmentProcedureStore = { state, getters, modules, mutations, actions };
