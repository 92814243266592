import { ActionTree, GetterTree, ModuleTree, MutationTree } from "vuex";
import { RootState } from "@/store/types";

import {
  ReportGeneral,
  ReportLocaleActions,
  ReportLocaleGetters,
  ReportLocaleMutations,
  ReportLocaleState,
  ReportState,
} from "@/store/report/type";
import obmed from "@/services/obmed";

const { VUE_APP_API_URL } = process.env;

const endpoints = {
  procedure: "relatorio-procedimentos/",
  patient: "relatorio-pacientes/",
  establishment: "relatorio-clinicas/",
};

const state: ReportLocaleState = {};

const getters: GetterTree<ReportState, RootState> & ReportLocaleGetters = {};

const mutations: MutationTree<ReportState> & ReportLocaleMutations = {};

const actions: ActionTree<ReportState, RootState> & ReportLocaleActions = {
  async getReportProcedure({ dispatch }) {
    try {
      const response = await obmed.get<ReportGeneral>({
        entity: endpoints.procedure,
        config: { baseURL: VUE_APP_API_URL },
      });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async getReportPatient({ dispatch }) {
    try {
      const response = await obmed.get<ReportGeneral>({
        entity: endpoints.patient,
        config: { baseURL: VUE_APP_API_URL },
      });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async getReportEstablishment({ dispatch }) {
    try {
      const response = await obmed.get<ReportGeneral>({
        entity: endpoints.establishment,
        config: { baseURL: VUE_APP_API_URL },
      });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
};
const modules: ModuleTree<RootState> = {};

export const ReportStore = { state, getters, modules, mutations, actions };
