import { RouteRecordRaw } from "vue-router";

import LAuth from "@/layouts/Auth.vue";
import LDashboard from "@/layouts/Dashboard.vue";

const unauthorizedRoutes: RouteRecordRaw[] = [
  {
    path: "/auth",
    name: "auth",
    component: LAuth,
    redirect: { name: "login" },
    meta: { requiresNotLogged: true },
    children: [
      {
        path: "/login",
        name: "login",
        component: () => import("@/views/Login.vue"),
      },
      {
        path: "/esqueci-minha-senha",
        name: "forgot-password",
        component: () => import("@/views/Forgot.vue"),
      },
      {
        path: "/forgotmypassword",
        redirect: (to) => ({ name: "recover-password", query: to.query }),
      },
      {
        path: "/redefinir-senha",
        name: "recover-password",
        component: () => import("@/views/Recover.vue"),
        beforeEnter: (to) => (!to.query.token ? { name: "root" } : true),
      },
    ],
  },
];

const authorizedRoutes: RouteRecordRaw[] = [
  {
    path: "/",
    name: "root",
    component: LDashboard,
    redirect: { name: "employee-list" },
    meta: { requiresAuth: true },
    children: [
      {
        path: "/usuarios",
        name: "employee-list",
        component: () => import("@/views/employee/List.vue"),
        children: [
          {
            path: "/usuarios/adicionar",
            name: "employee-add",
            component: () => import("@/views/employee/Form.vue"),
          },
          {
            path: "/usuarios/filtrar",
            name: "employee-filter",
            component: () => import("@/views/employee/Filter.vue"),
          },
          {
            path: "/usuarios/:_id/editar",
            name: "employee-edit",
            component: () => import("@/views/employee/Form.vue"),
          },
          {
            path: "/usuarios/:_id/remover",
            name: "employee-delete",
            component: () => import("@/views/employee/ConfirmDeletion.vue"),
          },
          {
            path: "/usuarios/:_id/atualizar-senha",
            name: "employee-edit-password",
            component: () => import("@/views/employee/UpdatePassword.vue"),
          },
          {
            path: "/relatorios",
            name: "report",
            redirect: { name: "report-procedures" },
            component: () => import("@/views/report/Report.vue"),
            children: [
              {
                path: "/relatorios/procedimentos",
                name: "report-procedures",
                component: () => import("@/views/report/General.vue"),
              },
              {
                path: "/relatorios/pacientes",
                name: "report-patients",
                component: () => import("@/views/report/General.vue"),
              },
              {
                path: "/relatorios/clinicas",
                name: "report-establishments",
                component: () => import("@/views/report/General.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "/pacientes",
        name: "patient-list",
        component: () => import("@/views/patient/List.vue"),
        children: [
          {
            path: "/pacientes/adicionar",
            name: "patient-add",
            component: () => import("@/views/patient/Form.vue"),
          },
          {
            path: "/pacientes/filtrar",
            name: "patient-filter",
            component: () => import("@/views/patient/Filter.vue"),
          },
          {
            path: "/pacientes/:_id/editar",
            name: "patient-edit",
            component: () => import("@/views/patient/Form.vue"),
          },
          {
            path: "/pacientes/:_id/remover",
            name: "patient-delete",
            component: () => import("@/views/patient/ConfirmDeletion.vue"),
          },
        ],
      },
      {
        path: "/especialistas",
        name: "specialist-list",
        component: () => import("@/views/specialist/List.vue"),
        children: [
          {
            path: "/especialistas/adicionar",
            name: "specialist-add",
            component: () => import("@/views/specialist/Form.vue"),
          },
          {
            path: "/especialistas/filtrar",
            name: "specialist-filter",
            component: () => import("@/views/specialist/Filter.vue"),
          },
          {
            path: "/especialistas/:_id/editar",
            name: "specialist-edit",
            component: () => import("@/views/specialist/Form.vue"),
          },
          {
            path: "/especialistas/:_id/remover",
            name: "specialist-delete",
            component: () => import("@/views/specialist/ConfirmDeletion.vue"),
          },
          {
            path: "/especialistas/:_id/vincular",
            name: "specialist-link",
            component: () => import("@/views/specialist/Link.vue"),
          },
          {
            path: "/especialistas/:_id/desvincular",
            name: "specialist-unlink",
            component: () => import("@/views/specialist/Unlink.vue"),
          },
        ],
      },
      {
        path: "/financeiro/guias-eletronicas",
        name: "guide-list",
        alias: "/home",
        component: () => import("@/views/guide/List.vue"),
        children: [
          {
            path: "/financeiro/guias-eletronicas/filtrar",
            name: "guide-filter",
            component: () => import("@/views/guide/Filter.vue"),
          },
          {
            path: "/financeiro/guias-eletronicas/adicionar/lote",
            name: "guide-add-batch",
            component: () => import("@/views/guide/BatchForm.vue"),
          },
          {
            path: "/financeiro/guias-eletronicas/:_id/contanto/email",
            name: "guide-contact-email",
            component: () => import("@/views/guide/Contact.vue"),
          },
          {
            path: "/financeiro/guias-eletronicas/:_id/contanto/whatsapp",
            name: "guide-contact-whatsapp",
            component: () => import("@/views/guide/Contact.vue"),
          },
        ],
      },
      {
        path: "/financeiro/caixas",
        name: "cashier-list",
        component: () => import("@/views/cashier/List.vue"),
        children: [
          {
            path: "/financeiro/caixas/adicionar",
            name: "cashier-add",
            component: () => import("@/views/cashier/Form.vue"),
          },
          {
            path: "/financeiro/caixas/:_id/editar",
            name: "cashier-edit",
            component: () => import("@/views/cashier/Form.vue"),
          },
          {
            path: "/financeiro/caixas/:_id/remover",
            name: "cashier-delete",
            component: () => import("@/views/cashier/ConfirmDeletion.vue"),
          },
        ],
      },
      {
        path: "/financeiro/lotes-de-pagamento",
        name: "batch-list",
        component: () => import("@/views/batch/List.vue"),
        children: [
          {
            path: "/financeiro/lotes-de-pagamento/:_id",
            name: "batch-details",
            component: () => import("@/views/batch/Details.vue"),
          },
          {
            path: "/financeiro/lotes-de-pagamento/:_id/pagar",
            name: "batch-pay",
            component: () => import("@/views/batch/UpdateStatus.vue"),
          },
          {
            path: "/financeiro/lotes-de-pagamento/:_id/cancelar",
            name: "batch-cancel",
            component: () => import("@/views/batch/UpdateStatus.vue"),
          },
        ],
      },
      {
        path: "/financeiro/historico",
        name: "cashier-historic-list",
        component: () => import("@/views/cashier-historic/List.vue"),
        children: [],
      },
      {
        path: "/financeiro/transacoes",
        name: "cashier-transaction-list",
        component: () => import("@/views/cashier-transaction/List.vue"),
        children: [
          {
            path: "/financeiro/transacoes/filtrar",
            name: "cashier-transaction-filter",
            component: () => import("@/views/cashier-transaction/Filter.vue"),
          },
          {
            path: "/financeiro/transacoes/guia/:_id",
            name: "cashier-transaction-guide",
            component: () => import("@/views/cashier-transaction/Guide.vue"),
          },
        ],
      },
      {
        path: "/financeiro/movimentacoes",
        name: "cashier-movement-list",
        component: () => import("@/views/cashier-movement/List.vue"),
        children: [
          {
            path: "/financeiro/movimentacoes/filtrar",
            name: "cashier-movement-filter",
            component: () => import("@/views/cashier-movement/Filter.vue"),
          },
        ],
      },
      {
        path: "/financeiro/faturamento",
        name: "payment-list",
        component: () => import("@/views/payment/List.vue"),
        children: [
          {
            path: "/financeiro/faturamento/adicionar",
            name: "payment-add",
            component: () => import("@/views/payment/Form.vue"),
          },
          {
            path: "/financeiro/faturamento/filtrar",
            name: "payment-filter",
            component: () => import("@/views/payment/Filter.vue"),
          },
          {
            path: "/financeiro/faturamento/:_id/faturar",
            name: "payment-make",
            component: () => import("@/views/payment/Form.vue"),
          },
          {
            path: "/financeiro/faturamento/:_id/editar",
            name: "payment-edit",
            component: () => import("@/views/payment/Form.vue"),
          },
          {
            path: "/financeiro/faturamento/:_id/remover",
            name: "payment-delete",
            component: () => import("@/views/payment/ConfirmDeletion.vue"),
          },
        ],
      },
      {
        path: "/procedimentos/cbhpm",
        name: "procedure-list",
        component: () => import("@/views/procedure/List.vue"),
        children: [
          {
            path: "/procedimentos/cbhpm/filtrar",
            name: "procedure-filter",
            component: () => import("@/views/procedure/Filter.vue"),
          },
          {
            path: "/procedimentos/cbhpm/adicionar",
            name: "procedure-add",
            component: () => import("@/views/procedure/Form.vue"),
          },
          {
            path: "/procedimentos/cbhpm/:_id/editar",
            name: "procedure-edit",
            component: () => import("@/views/procedure/Form.vue"),
          },
          {
            path: "/procedimentos/cbhpm/:_id/remover",
            name: "procedure-delete",
            component: () => import("@/views/procedure/ConfirmDeletion.vue"),
          },
        ],
      },
      {
        path: "/procedimentos/negociados",
        name: "establishment-procedure-list",
        component: () => import("@/views/establishment-procedure/List.vue"),
        children: [
          {
            path: "/procedimentos/negociados/filtrar",
            name: "establishment-procedure-filter",
            component: () => import("@/views/establishment-procedure/Filter.vue"),
          },
          {
            path: "/procedimentos/negociados/adicionar",
            name: "establishment-procedure-add",
            component: () => import("@/views/establishment-procedure/Form.vue"),
            children: [
              {
                path: "/procedimentos/negociados/adicionar/cbhpm",
                name: "establishment-procedure-add-cbhpm",
                component: () => import("@/views/procedure/Form.vue"),
              },
            ],
          },
          {
            path: "/procedimentos/negociados/:_id/editar",
            name: "establishment-procedure-edit",
            component: () => import("@/views/establishment-procedure/Form.vue"),
          },
          {
            path: "/procedimentos/negociados/:_id/remover",
            name: "establishment-procedure-delete",
            component: () => import("@/views/establishment-procedure/ConfirmDeletion.vue"),
          },
        ],
      },
      {
        path: "/parceiros",
        name: "unit-list",
        component: () => import("@/views/unit/List.vue"),
        children: [
          {
            path: "/parceiros/adicionar",
            name: "unit-add",
            component: () => import("@/views/unit/Form.vue"),
          },
          {
            path: "/parceiros/:_id/editar",
            name: "unit-edit",
            component: () => import("@/views/unit/Form.vue"),
          },
          {
            path: "/parceiros/:_id/remover",
            name: "unit-delete",
            component: () => import("@/views/unit/ConfirmDeletion.vue"),
          },
        ],
      },
      {
        path: "/clinicas",
        name: "establishment-list",
        component: () => import("@/views/establishment/List.vue"),
        children: [
          {
            path: "/clinicas/adicionar",
            name: "establishment-add",
            component: () => import("@/views/establishment/Form.vue"),
          },
          {
            path: "/clinicas/:_id/editar",
            name: "establishment-edit",
            component: () => import("@/views/establishment/Form.vue"),
          },
          {
            path: "/clinicas/:_id/remover",
            name: "establishment-delete",
            component: () => import("@/views/establishment/ConfirmDeletion.vue"),
          },
        ],
      },
      {
        path: "/profissionais-autonomos",
        name: "self-employed-list",
        component: () => import("@/views/self-employed/List.vue"),
        children: [
          {
            path: "/profissionais-autonomos/adicionar",
            name: "self-employed-add",
            component: () => import("@/views/self-employed/Form.vue"),
          },
          {
            path: "/profissionais-autonomos/:_id/editar",
            name: "self-employed-edit",
            component: () => import("@/views/self-employed/Form.vue"),
          },
          {
            path: "/profissionais-autonomos/:_id/remover",
            name: "self-employed-delete",
            component: () => import("@/views/self-employed/ConfirmDeletion.vue"),
          },
        ],
      },
      {
        path: "/especialidades",
        name: "specialty-list",
        component: () => import("@/views/specialty/List.vue"),
        children: [
          {
            path: "/especialidades/adicionar",
            name: "specialty-add",
            component: () => import("@/views/specialty/Form.vue"),
          },
          {
            path: "/especialidades/:_id/editar",
            name: "specialty-edit",
            component: () => import("@/views/specialty/Form.vue"),
          },
          {
            path: "/especialidades/:_id/remover",
            name: "specialty-delete",
            component: () => import("@/views/specialty/ConfirmDeletion.vue"),
          },
        ],
      },
      {
        path: "/bancos",
        name: "bank-list",
        component: () => import("@/views/bank/List.vue"),
        children: [
          {
            path: "/bancos/adicionar",
            name: "bank-add",
            component: () => import("@/views/bank/Form.vue"),
          },
          {
            path: "/bancos/:_id/editar",
            name: "bank-edit",
            component: () => import("@/views/bank/Form.vue"),
          },
          {
            path: "/bancos/:_id/remover",
            name: "bank-delete",
            component: () => import("@/views/bank/ConfirmDeletion.vue"),
          },
        ],
      },
      {
        path: "/cupons",
        name: "voucher-list",
        component: () => import("@/views/voucher/List.vue"),
        children: [
          {
            path: "/cupons/aplicados",
            name: "voucher-user",
            component: () => import("@/views/voucher/User.vue"),
          },
          {
            path: "/cupons/adicionar",
            name: "voucher-add",
            component: () => import("@/views/voucher/Form.vue"),
          },
          {
            path: "/cupons/:_id/editar",
            name: "voucher-edit",
            component: () => import("@/views/voucher/Form.vue"),
          },
        ],
      },
      {
        path: "/permissoes",
        name: "permission-list",
        component: () => import("@/views/permission/List.vue"),
        children: [
          {
            path: "/permissoes/adicionar",
            name: "permission-add",
            component: () => import("@/views/permission/Form.vue"),
          },
          {
            path: "/permissoes/:_id/editar",
            name: "permission-edit",
            component: () => import("@/views/permission/Form.vue"),
          },
          {
            path: "/permissoes/:_id/remover",
            name: "permission-delete",
            component: () => import("@/views/permission/ConfirmDeletion.vue"),
          },
        ],
      },
      {
        path: "/grupos",
        name: "group-list",
        component: () => import("@/views/group/List.vue"),
        children: [
          {
            path: "/grupos/adicionar",
            name: "group-add",
            component: () => import("@/views/group/Form.vue"),
          },
          {
            path: "/grupos/:_id/editar",
            name: "group-edit",
            component: () => import("@/views/group/Form.vue"),
          },
          {
            path: "/grupos/:_id/remover",
            name: "group-delete",
            component: () => import("@/views/group/ConfirmDeletion.vue"),
          },
        ],
      },
      {
        path: "/sair",
        name: "logout",
        component: () => import("@/views/Logout.vue"),
      },
    ],
  },
];

const routes: RouteRecordRaw[] = [
  ...unauthorizedRoutes,
  ...authorizedRoutes,
  {
    path: "/:pathMatch(.*)*",
    name: "page-not-found",
    component: () => import("@/layouts/NotFound.vue"),
  },
];

export default routes;
