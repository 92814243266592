
import { defineComponent, ref } from "vue";

const CScrollToTop = defineComponent({
  name: "CScrollToTop",
  setup() {
    const show = ref(false);

    function handleScroll() {
      if ((document.scrollingElement?.scrollTop || 0) > 48) show.value = true;
      else show.value = false;
    }

    function applyScrollToTop() {
      if (document.scrollingElement) window.scrollTo({ top: 0, behavior: "smooth" });
    }

    window.onscroll = handleScroll;

    return { show, applyScrollToTop };
  },
});

export default CScrollToTop;
