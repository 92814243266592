import obmed from "@/services/obmed";
import { ActionTree, GetterTree, MutationTree } from "vuex";

import { RootState } from "@/store/types";
import {
  SelfEmployed,
  SelfEmployedForm,
  SelfEmployedState,
  SelfEmployedLocaleState,
  SelfEmployedLocaleMutations,
  SelfEmployedLocaleActions,
  SelfEmployedLocaleGetters,
} from "@/store/self-employed/types";
import { PaginationParams, PaginationResponse } from "../types";

const { VUE_APP_API_URL } = process.env;

const endpoints = {
  root: "estabelecimento/?ie_autonomo=true",
  byID: (_id: number) => `estabelecimento/${_id}/?ie_autonomo=true`,
  specialty: "especialidade-estabelecimento/?ie_autonomo=true",
  specialtyByID: (_id: number) => `especialidade-estabelecimento/${_id}/?ie_autonomo=true`,
};

const state: SelfEmployedLocaleState = {
  current: null,
  list: [],
  pagination: { page: 1, total: 1, items: 0, perPage: 25 },
};

const getters: GetterTree<SelfEmployedState, RootState> & SelfEmployedLocaleGetters = {};

const mutations: MutationTree<SelfEmployedState> & SelfEmployedLocaleMutations = {
  setSelfEmployed(state, selfEmployed) {
    state.current = selfEmployed;
  },
  setSelfEmployedList(state, selfEmployedList) {
    state.list = selfEmployedList;
  },
  setSelfEmployedPagination(state, pagination) {
    state.pagination = pagination;
  },
  setSelfEmployedPaginationPage(state, { page }) {
    state.pagination.page = page;
  },
};

const actions: ActionTree<SelfEmployedState, RootState> & SelfEmployedLocaleActions = {
  async getSelfEmployed({ commit, dispatch }, { _id }) {
    try {
      const response = await obmed.get<SelfEmployed>({
        entity: endpoints.byID(_id),
        config: { baseURL: VUE_APP_API_URL },
      });
      commit("setSelfEmployed", response.data);

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async getSelfEmployedList({ state, commit, dispatch }) {
    const pagination: PaginationParams = {
      page: state.pagination.page,
      per_page: state.pagination.perPage,
      paginacao: true,
    };
    try {
      const response = await obmed.get<PaginationResponse<SelfEmployed>>({
        entity: endpoints.root,
        config: { query: { ...pagination }, baseURL: VUE_APP_API_URL },
      });

      commit("setSelfEmployedList", response.data?.results || []);
      commit("setSelfEmployedPagination", { ...state.pagination, items: response.data.count });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async createSelfEmployed({ commit, dispatch }, { form }) {
    try {
      const response = await obmed.post<any, SelfEmployedForm>({
        entity: endpoints.root,
        data: form,
        config: { baseURL: VUE_APP_API_URL },
      });

      if (response?.status === 201)
        commit("addToast", {
          summary: "Cadastro realizado com sucesso!",
          severity: "success",
        });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async updateSelfEmployed({ commit, dispatch }, { _id, form }) {
    try {
      const response = await obmed.patch<any, SelfEmployedForm>({
        entity: endpoints.byID(_id),
        data: form,
        config: { baseURL: VUE_APP_API_URL },
      });

      if (response?.status === 200)
        commit("addToast", {
          summary: "Dados atualizados com sucesso!",
          severity: "success",
        });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async updateSelfEmployedPhoto({ dispatch }, { _id, aq_foto }) {
    try {
      const form = new FormData();
      form.append("aq_foto", aq_foto, aq_foto.name || "clinica");

      const response = await obmed.patch<any>({
        entity: endpoints.byID(_id),
        data: form,
        config: { baseURL: VUE_APP_API_URL },
      });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async deleteSelfEmployed({ commit, dispatch }, { _id }) {
    try {
      const response = await obmed.delete({
        entity: endpoints.byID(_id),
        config: { baseURL: VUE_APP_API_URL },
      });

      if (response?.status === 201)
        commit("addToast", {
          summary: "Unidade removida com sucesso!",
          severity: "success",
        });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async addSelfEmployedSpecialty({ dispatch }, { form }) {
    try {
      const response = await obmed.post<any>({
        entity: endpoints.specialty,
        data: form,
        config: { baseURL: VUE_APP_API_URL, useCompany: false },
      });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async removeSelfEmployedSpecialty({ dispatch }, { _id, cd_estabelecimento }) {
    try {
      const response = await obmed.delete({
        entity: endpoints.specialtyByID(_id),
        config: { query: { cd_estabelecimento }, baseURL: VUE_APP_API_URL, useCompany: false },
      });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async getAllSelfEmployed({ commit, dispatch }) {
    try {
      const response = await obmed.get<SelfEmployed[]>({
        entity: endpoints.root,
        config: { baseURL: VUE_APP_API_URL },
      });
      commit("setAllSelfEmployed", response.data);

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
};

export const SelfEmployedStore = { state, getters, mutations, actions };
