
import { computed, defineComponent, ref, watch } from "vue";
import { useStore } from "vuex";

import { Toast } from "@/store/types";

const CToast = defineComponent({
  name: "CToast",
  setup() {
    const store = useStore();
    const hasToast = computed(() => store.getters.hasToast);

    const current = ref<Toast | null>(null);
    const show = ref(false);
    const animationDuration = 500;

    function showToast() {
      if (!hasToast.value) return;

      let delay = 0;
      for (const message of store.state.toast.messages) {
        setTimeout(() => {
          current.value = message;
          show.value = true;

          setTimeout(() => {
            current.value = null;
            show.value = false;
          }, message.life + animationDuration);
        }, delay);
        delay += message.life + animationDuration + 100;
      }

      store.commit("setToast", []);
    }

    function handleClose() {
      store.commit("setToast", []);
      current.value = null;
      show.value = false;
    }
    watch(hasToast, showToast);

    return { show, current, handleClose };
  },
});

export default CToast;
