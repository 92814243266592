
import { defineComponent } from "vue";

const LAuth = defineComponent({
  name: "LAuth",
  setup() {
    return {};
  },
});

export default LAuth;
