import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "l-dashboard" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_appbar = _resolveComponent("c-appbar")!
  const _component_c_sidebar = _resolveComponent("c-sidebar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_c_spinner = _resolveComponent("c-spinner")!
  const _component_c_footer = _resolveComponent("c-footer")!
  const _component_c_scroll_to_top = _resolveComponent("c-scroll-to-top")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_c_appbar, { "onShow:sidebar": _ctx.updateSidebarStatus }, null, 8, ["onShow:sidebar"]),
    _createVNode(_component_c_sidebar, {
      isActive: _ctx.isActiveSidebar,
      "onShow:sidebar": _ctx.updateSidebarStatus
    }, null, 8, ["isActive", "onShow:sidebar"]),
    (_ctx.user?.id)
      ? (_openBlock(), _createBlock(_component_router_view, {
          key: 0,
          class: "content-children"
        }))
      : (_openBlock(), _createBlock(_component_c_spinner, { key: 1 })),
    _createVNode(_component_c_footer),
    _createVNode(_component_c_scroll_to_top)
  ]))
}