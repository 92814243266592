import obmed from "@/services/obmed";
import { ActionTree, GetterTree, ModuleTree, MutationTree } from "vuex";

import { RootState } from "@/store/types";
import {
  CashierHistoric,
  CashierHistoricState,
  CashierHistoricLocaleState,
  CashierHistoricLocaleMutations,
  CashierHistoricLocaleActions,
  CashierHistoricLocaleGetters,
} from "@/store/cashier/historic/types";
import { PaginationParams, PaginationResponse } from "@/store/types";

const { VUE_APP_API_URL } = process.env;

const endpoints = {
  root: "obmed/caixas/historico/",
};

const state: CashierHistoricLocaleState = {
  list: [],
  pagination: { page: 1, total: 1, items: 0, perPage: 25 },
};

const getters: GetterTree<CashierHistoricState, RootState> & CashierHistoricLocaleGetters = {};

const mutations: MutationTree<CashierHistoricState> & CashierHistoricLocaleMutations = {
  setCashierHistoricList(state, historicList) {
    state.list = historicList;
  },
  setCashierHistoricPagination(state, pagination) {
    state.pagination = pagination;
  },
  setCashierHistoricPaginationPage(state, { page }) {
    state.pagination.page = page;
  },
};

const actions: ActionTree<CashierHistoricState, RootState> & CashierHistoricLocaleActions = {
  async getCashierHistoricList({ state, commit, dispatch }) {
    const pagination: PaginationParams = {
      page: state.pagination.page,
      per_page: state.pagination.perPage,
      paginacao: true,
    };
    try {
      const response = await obmed.get<PaginationResponse<CashierHistoric>>({
        entity: endpoints.root,
        config: { query: { ...pagination }, baseURL: VUE_APP_API_URL },
      });

      commit("setCashierHistoricList", response.data?.results || []);
      commit("setCashierHistoricPagination", { ...state.pagination, items: response.data.count });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
};

const modules: ModuleTree<RootState> = {};

export const CashierHistoricStore = { state, getters, modules, mutations, actions };
