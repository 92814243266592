import { computed } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

export type PaginationEvent = {
  page: number;
  first: number;
  rows: 8 | 12 | 16 | 24 | 32;
  pageCount: number;
};

export function usePagination(params: {
  module: string | any;
  setPage: string | any;
  field?: string;
  updateList: () => any;
}) {
  const store = useStore();
  const route = useRoute();
  const router = useRouter();
  const [module, secondaryModule] = params.module.split(".");

  const pagination = computed(() => {
    const storeModule = (store.state as any)[module];

    return (secondaryModule ? storeModule[secondaryModule] : storeModule)[params.field || "pagination"];
  });

  function handleUpdateCurrentPage(event: PaginationEvent & any) {
    const page = (event.page || 0) + 1;

    router.replace({ query: { ...route.query, pg: page } });
    store.commit(params.setPage, { page });

    params.updateList();
  }

  const currentPage = Number(route.query.pg) || 1;

  store.commit(params.setPage, { page: currentPage });
  router.replace({ query: { ...route.query, pg: currentPage } });

  return { pagination, handleUpdateCurrentPage };
}

export default usePagination;
