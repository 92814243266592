import obmed from "@/services/obmed";
import { ActionTree, GetterTree, ModuleTree, MutationTree } from "vuex";

import { RootState } from "@/store/types";
import {
  Employee,
  EmployeeState,
  EmployeeLocaleState,
  EmployeeLocaleMutations,
  EmployeeLocaleActions,
  EmployeeLocaleGetters,
  EmployeeForm,
} from "@/store/employee/types";
import { PaginationParams, PaginationResponse } from "../types";

const { VUE_APP_API_URL } = process.env;

const endpoints = {
  root: "usuario/",
  byID: (_id: number | string) => `usuario/${_id}/`,
};

const state: EmployeeLocaleState = {
  current: null,
  list: [],
  searchList: [],
  pagination: { page: 1, total: 1, items: 0, perPage: 25 },
};

const getters: GetterTree<EmployeeState, RootState> & EmployeeLocaleGetters = {};

const mutations: MutationTree<EmployeeState> & EmployeeLocaleMutations = {
  setEmployee(state, employee) {
    state.current = employee;
  },
  setEmployees(state, employees) {
    state.list = employees;
  },
  setSearchEmployees(state, employees) {
    state.searchList = employees;
  },
  setEmployeePagination(state, pagination) {
    state.pagination = pagination;
  },
  setEmployeePaginationPage(state, { page }) {
    state.pagination.page = page;
  },
};

const actions: ActionTree<EmployeeState, RootState> & EmployeeLocaleActions = {
  async getEmployee({ commit, dispatch }, { _id }) {
    try {
      const response = await obmed.get<Employee>({
        entity: endpoints.byID(_id),
        config: { baseURL: VUE_APP_API_URL },
      });
      commit("setEmployee", response.data);

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async getEmployees({ state, commit, dispatch }, { config } = { config: {} }) {
    const pagination: PaginationParams = {
      page: state.pagination.page,
      per_page: state.pagination.perPage,
      paginacao: true,
    };
    try {
      const response = await obmed.get<PaginationResponse<Employee>>({
        entity: endpoints.root,
        config: { query: { ...pagination }, baseURL: VUE_APP_API_URL, ...config },
      });

      commit("setEmployees", response.data?.results || []);
      commit("setEmployeePagination", { ...state.pagination, items: response.data.count });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async createEmployee({ commit, dispatch }, { form }) {
    try {
      const response = await obmed.post<any, EmployeeForm>({
        entity: endpoints.root,
        data: form,
        config: { baseURL: VUE_APP_API_URL },
      });

      if (response?.status === 201)
        commit("addToast", {
          summary: "Cadastro realizado com sucesso!",
          severity: "success",
        });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async updateEmployee({ commit, dispatch }, { _id, form }) {
    try {
      const response = await obmed.patch<any, EmployeeForm>({
        entity: endpoints.byID(_id),
        data: form,
        config: { baseURL: VUE_APP_API_URL },
      });

      if (response?.status === 200)
        commit("addToast", {
          summary: "Dados atualizados com sucesso!",
          severity: "success",
        });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async deleteEmployee({ commit, dispatch }, { _id }) {
    try {
      const response = await obmed.delete({
        entity: endpoints.byID(_id),
        config: { baseURL: VUE_APP_API_URL },
      });

      if (response?.status === 201)
        commit("addToast", {
          summary: "Funcionário removido com sucesso!",
          severity: "success",
        });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async updateEmployeePassword({ commit, dispatch }, { _id, form }) {
    try {
      const response = await obmed.patch({
        entity: endpoints.byID(_id),
        data: form,
        config: { baseURL: VUE_APP_API_URL },
      });

      if (response?.status === 200)
        commit("addToast", {
          summary: "Senha atualizada com sucesso!",
          severity: "success",
        });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async searchEmployees({ state, commit, dispatch }, { search, isPrimary, config }) {
    try {
      const pagination: PaginationParams = { page: 1, per_page: 25, paginacao: true };
      const response = await obmed.get<PaginationResponse<Employee>>({
        entity: endpoints.root,
        config: { query: { ...search, ...pagination, ...(config || {}) }, baseURL: VUE_APP_API_URL },
      });

      commit("setSearchEmployees", response.data?.results || []);

      if (isPrimary) {
        commit("setEmployees", response.data?.results || []);
        commit("setEmployeePagination", { ...state.pagination, items: response.data.count });
      }

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
};

const modules: ModuleTree<RootState> = {};

export const EmployeeStore = { state, getters, modules, mutations, actions };
