import obmed from "@/services/obmed";
import { ActionTree, GetterTree, ModuleTree, MutationTree } from "vuex";

import { RootState } from "@/store/types";
import {
  User,
  UserState,
  UserLocaleState,
  UserLocaleMutations,
  UserLocaleActions,
  UserLocaleGetters,
  UserGroup,
  UserEstablishment,
} from "@/store/user/types";
import { SessionStore } from "@/store/user/session";

const { VUE_APP_API_URL } = process.env;

const endpoints = {
  userdata: "userdata/",
  group: "grupo/",
  establishment: "estabelecimento/",
  forgotPassword: "esqueci-minha-senha/",
  recoverPassword: "esqueci-minha-senha/criar-nova-senha/",
};

const state: UserLocaleState = {
  current: null,
  establishment: null,
  groups: [],
  establishments: [],
};

const getters: GetterTree<UserState, RootState> & UserLocaleGetters = {
  userData(state) {
    return state.current;
  },
};

const mutations: MutationTree<UserState> & UserLocaleMutations = {
  setCurrentUser(state, user) {
    state.current = user;
    if (user) localStorage.setItem("USER_ID", String(user.id));
  },
  setUserGroups(state, groups) {
    state.groups = groups;
  },
  setUserEstablishments(state, establishments) {
    state.establishments = establishments;
  },
  setUserEstablishment(state, establishment) {
    if (state.current?.estabelecimentos?.length) {
      establishment = establishment || state.current.estabelecimentos[0];
      localStorage.setItem("ESTABLISHMENT", JSON.stringify(establishment));
      localStorage.setItem("CD_ESTABELECIMENTO", String(establishment?.pk));
      state.establishment = establishment;
    } else {
      localStorage.removeItem("ESTABLISHMENT");
      localStorage.removeItem("CD_ESTABELECIMENTO");
      state.establishment = null;
    }
  },
};

const actions: ActionTree<UserState, RootState> & UserLocaleActions = {
  async getUserData({ commit }) {
    try {
      const response = await obmed.get<User>({
        entity: endpoints.userdata,
        config: { baseURL: VUE_APP_API_URL, useCompany: false },
      });
      commit("setCurrentUser", response.data);
      commit(
        "setUserEstablishment",
        localStorage.getItem("ESTABLISHMENT") ? JSON.parse(String(localStorage.getItem("ESTABLISHMENT"))) : null
      );

      return response;
    } catch (error) {
      if (process.env.NODE_ENV === "development") console.error(error);
    }
  },
  async getUserGroups({ commit, dispatch }) {
    try {
      const response = await obmed.get<UserGroup[]>({
        entity: endpoints.group,
        config: { baseURL: VUE_APP_API_URL },
      });
      commit("setUserGroups", response.data);

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async getEmployeeEstablishments({ commit, dispatch }) {
    try {
      const response = await obmed.get<UserEstablishment[]>({
        entity: endpoints.establishment,
        config: { useCompany: false, baseURL: VUE_APP_API_URL },
      });
      commit("setUserEstablishments", response.data);

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async forgotPassword({ commit, dispatch }, payload) {
    try {
      const response = await obmed.post<any>({
        entity: endpoints.forgotPassword,
        data: payload.form,
        config: {
          baseURL: VUE_APP_API_URL,
          useCompany: false,
          useToken: false,
        },
      });

      if (response?.statusText?.toLowerCase() === "ok")
        commit("setToast", [
          {
            summary: response.data.detail || response.data.info,
            severity: "success",
            life: 10000,
          },
        ]);

      return response;
    } catch (error: any) {
      if (error.isObmedError) commit("setToast", [{ summary: "Usuário não encontrado!", severity: "error" }]);
      else dispatch("handleError", error);
    }
  },
  async recoverPassword({ dispatch }, payload) {
    try {
      const response = await obmed.post<any>({
        entity: endpoints.recoverPassword,
        data: payload.form,
        config: { baseURL: VUE_APP_API_URL, useCompany: false, useToken: false },
      });

      return response;
    } catch (error: any) {
      error.messages = [
        {
          summary:
            "Não foi possível redefinir sua senha no momento, talvez o token de recuperação tenha expirado, tente novamente mais tarde!",
          severity: "error",
        },
      ];
      dispatch("handleError", error);
    }
  },
};

const modules: ModuleTree<RootState> = {
  session: SessionStore,
};

export const UserStore = { state, getters, modules, mutations, actions };
