export default [
  {
    label: "Pacientes",
    to: { name: "patient-list" },
    icon: "fas fa-hospital-user",
    permission: "paciente:::get",
  },
  {
    label: "Especialistas",
    to: { name: "specialist-list" },
    icon: "fas fa-user-md",
    permission: "especialista:::get",
  },
  {
    label: "Procedimentos",
    icon: "fas fa-briefcase-medical",
    items: [
      {
        label: "Negociados",
        to: { name: "establishment-procedure-list" },
        icon: "fas fa-hand-holding-heart",
        permission: "procedimentos/negociados:::get",
      },
      {
        label: "CBHPM",
        to: { name: "procedure-list" },
        icon: "fas fa-hospital-alt",
        permission: "procedimentos/cbhpm:::get",
      },
    ],
  },
  {
    label: "Financeiro",
    icon: "fas fa-wallet",
    items: [
      {
        label: "Guias eletrônicas",
        to: { name: "guide-list" },
        icon: "fas fa-ticket-alt",
        permission: "atendimento:::get",
      },
      {
        label: "Lotes de pagamento",
        to: { name: "batch-list" },
        icon: "fas fa-boxes-packing",
        permission: "atendimento:::get",
      },
      {
        label: "Transações",
        to: { name: "cashier-transaction-list" },
        icon: "fas fa-coins",
        permission: "transacao-financeira:::get",
      },
      {
        label: "Cupons ",
        to: { name: "voucher-list" },
        icon: "fas fa-receipt",
        permission: "banco:::get",
      },
      {
        label: "Bancos",
        to: { name: "bank-list" },
        icon: "fas fa-piggy-bank",
        permission: "banco:::get",
      },
    ],
  },
  {
    label: "Integrantes",
    icon: "fas fa-hand-holding-medical",
    items: [
      {
        label: "Clínicas",
        to: { name: "establishment-list" },
        icon: "fas fa-hospital-alt",
        permission: "estabelecimento:::get",
      },
      {
        label: "Autônomos",
        to: { name: "self-employed-list" },
        icon: "fas fa-user-doctor",
        permission: "estabelecimento:::get",
      },
    ],
  },
  {
    label: "Sistema",
    icon: "fas fa-users-cog",
    items: [
      {
        label: "Usuários",
        to: { name: "employee-list" },
        icon: "fas fa-user-friends",
        permission: "usuario:::get",
      },
      {
        label: "Permissões",
        to: { name: "permission-list" },
        icon: "fas fa-user-shield",
        permission: "endpoint:::get",
      },
      {
        label: "Grupos",
        to: { name: "group-list" },
        icon: "fas fa-users",
        permission: "grupo:::get",
      },
      {
        label: "Especialidades",
        to: { name: "specialty-list" },
        icon: "fas fa-stethoscope",
        permission: "especialidade:::get",
      },
    ],
  },
  {
    label: "Relatórios",
    to: { name: "report" },
    icon: "fas fa-print",
    permission: "relatorio:::get",
  },
];
