import { createRouter, createWebHistory } from "vue-router";

import { store } from "@/store";
import routes from "@/router/routes";

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from) {
    return to.hash
      ? { el: to.hash, top: window.innerHeight / 5, behavior: "smooth" }
      : to.name !== from.name
      ? { top: 0, behavior: "smooth" }
      : {};
  },
});

router.beforeEach((to, _, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresNotLogged = to.matched.some((record) => record.meta.requiresNotLogged);

  if (requiresAuth) {
    if (store.getters.isLogged) next();
    else next({ name: "login" });
  } else {
    if (store.getters.isLogged && requiresNotLogged) next({ name: "root" });
    else next();
  }
});

export default router;
