
import { computed, defineComponent, ref, watch } from "vue";
import { useStore } from "vuex";

import CAppbar from "@/components/Appbar.vue";
import CSidebar from "@/components/Sidebar.vue";
import CFooter from "@/components/Footer.vue";
import CSpinner from "@/components/Spinner.vue";
import CScrollToTop from "@/components/ScrollToTop.vue";

const LDashboard = defineComponent({
  name: "LDashboard",
  components: { CAppbar, CSidebar, CFooter, CSpinner, CScrollToTop },
  setup() {
    const store = useStore();
    const isActiveSidebar = ref(false);
    const isLogged = computed(() => store.getters.isLogged);
    const user = computed(() => store.state.user.current);

    function getUserData() {
      if (isLogged.value) store.dispatch("getUserData");
    }

    function updateSidebarStatus(show: boolean) {
      isActiveSidebar.value = show;
    }

    watch(isLogged, getUserData);
    getUserData();

    return { user, isActiveSidebar, updateSidebarStatus };
  },
});

export default LDashboard;
