
import { defineComponent } from "vue";
import CToast from "@/components/Toast.vue";

const App = defineComponent({
  name: "App",
  components: { CToast },
});

export default App;
