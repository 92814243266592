import obmed from "@/services/obmed";
import { ActionTree, GetterTree, ModuleTree, MutationTree } from "vuex";

import { RootState } from "@/store/types";
import {
  Establishment,
  EstablishmentState,
  EstablishmentLocaleState,
  EstablishmentLocaleMutations,
  EstablishmentLocaleActions,
  EstablishmentLocaleGetters,
  EstablishmentForm,
} from "@/store/establishment/types";
import { PaginationParams, PaginationResponse } from "../types";
import { EstablishmentProcedureStore } from "./procedure";

const { VUE_APP_API_URL } = process.env;

const endpoints = {
  root: "estabelecimento/",
  byID: (_id: number) => `estabelecimento/${_id}/`,
  specialty: "especialidade-estabelecimento/",
  specialtyByID: (_id: number) => `especialidade-estabelecimento/${_id}/`,
};

const state: EstablishmentLocaleState = {
  current: null,
  list: [],
  all: [],
  pagination: { page: 1, total: 1, items: 0, perPage: 25 },
};

const getters: GetterTree<EstablishmentState, RootState> & EstablishmentLocaleGetters = {};

const mutations: MutationTree<EstablishmentState> & EstablishmentLocaleMutations = {
  setEstablishment(state, establishment) {
    state.current = establishment;
  },
  setEstablishments(state, establishments) {
    state.list = establishments;
  },
  setAllEstablishment(state, establishment) {
    state.all = establishment;
  },
  setEstablishmentPagination(state, pagination) {
    state.pagination = pagination;
  },
  setEstablishmentPaginationPage(state, { page }) {
    state.pagination.page = page;
  },
};

const actions: ActionTree<EstablishmentState, RootState> & EstablishmentLocaleActions = {
  async getEstablishment({ commit, dispatch }, { _id }) {
    try {
      const response = await obmed.get<Establishment>({
        entity: endpoints.byID(_id),
        config: { baseURL: VUE_APP_API_URL },
      });
      commit("setEstablishment", response.data);

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async getEstablishments({ state, commit, dispatch }) {
    const pagination: PaginationParams = {
      page: state.pagination.page,
      per_page: state.pagination.perPage,
      paginacao: true,
    };
    try {
      const response = await obmed.get<PaginationResponse<Establishment>>({
        entity: endpoints.root,
        config: { query: { ...pagination }, baseURL: VUE_APP_API_URL },
      });

      commit("setEstablishments", response.data?.results || []);
      commit("setEstablishmentPagination", { ...state.pagination, items: response.data.count });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async createEstablishment({ commit, dispatch }, { form }) {
    try {
      const response = await obmed.post<any, EstablishmentForm>({
        entity: endpoints.root,
        data: form,
        config: { baseURL: VUE_APP_API_URL },
      });

      if (response?.status === 201)
        commit("addToast", {
          summary: "Cadastro realizado com sucesso!",
          severity: "success",
        });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async updateEstablishment({ commit, dispatch }, { _id, form }) {
    try {
      const response = await obmed.patch<any, EstablishmentForm>({
        entity: endpoints.byID(_id),
        data: form,
        config: { baseURL: VUE_APP_API_URL },
      });

      if (response?.status === 200)
        commit("addToast", {
          summary: "Dados atualizados com sucesso!",
          severity: "success",
        });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async updateEstablishmentPhoto({ dispatch }, { _id, aq_foto }) {
    try {
      const form = new FormData();
      form.append("aq_foto", aq_foto, aq_foto.name || "clinica");

      const response = await obmed.patch<any>({
        entity: endpoints.byID(_id),
        data: form,
        config: { baseURL: VUE_APP_API_URL },
      });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async deleteEstablishment({ commit, dispatch }, { _id }) {
    try {
      const response = await obmed.delete({
        entity: endpoints.byID(_id),
        config: { baseURL: VUE_APP_API_URL },
      });

      if (response?.status === 201)
        commit("addToast", {
          summary: "Unidade removida com sucesso!",
          severity: "success",
        });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async addEstablishmentSpecialty({ dispatch }, { form }) {
    try {
      const response = await obmed.post<any>({
        entity: endpoints.specialty,
        data: form,
        config: { baseURL: VUE_APP_API_URL, useCompany: false },
      });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async removeEstablishmentSpecialty({ dispatch }, { _id, cd_estabelecimento }) {
    try {
      const response = await obmed.delete({
        entity: endpoints.specialtyByID(_id),
        config: { query: { cd_estabelecimento }, baseURL: VUE_APP_API_URL, useCompany: false },
      });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async getAllEstablishment({ commit, dispatch }) {
    try {
      const response = await obmed.get<Establishment[]>({
        entity: endpoints.root,
        config: { baseURL: VUE_APP_API_URL },
      });
      commit("setAllEstablishment", response.data);

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
};

const modules: ModuleTree<RootState> = { procedure: EstablishmentProcedureStore };

export const EstablishmentStore = { state, getters, modules, mutations, actions };
