
import { defineComponent } from "vue";
import { formatPhone } from "@/libs/utils";

const CFooter = defineComponent({
  name: "CFooter",
  setup() {
    const { VUE_APP_INSTAGRAM, VUE_APP_FACEBOOK, VUE_APP_WHATSAPP, VUE_APP_WHATSAPP_MESSAGE } = process?.env || {};

    return { VUE_APP_INSTAGRAM, VUE_APP_FACEBOOK, VUE_APP_WHATSAPP, VUE_APP_WHATSAPP_MESSAGE, formatPhone };
  },
});

export default CFooter;
