import obmed from "@/services/obmed";
import { ActionTree, GetterTree, ModuleTree, MutationTree } from "vuex";

import { RootState } from "@/store/types";
import {
  Batch,
  BatchState,
  BatchLocaleState,
  BatchLocaleMutations,
  BatchLocaleActions,
  BatchLocaleGetters,
  BatchForm,
} from "@/store/batch/types";
import { PaginationParams, PaginationResponse } from "../types";

const { VUE_APP_API_URL } = process.env;

const endpoints = {
  root: "lote/",
  byID: (_id: number | string) => `lote/${_id}/`,
  pay: (_id: number | string) => `pagar-lote/${_id}/`,
  cancel: (_id: number | string) => `cancelar-lote/${_id}/`,
};

const state: BatchLocaleState = {
  current: null,
  list: [],
  selectedGuides: [],
  pagination: { page: 1, total: 1, items: 0, perPage: 25 },
};

const getters: GetterTree<BatchState, RootState> & BatchLocaleGetters = {};

const mutations: MutationTree<BatchState> & BatchLocaleMutations = {
  setBatch(state, batch) {
    state.current = batch;
  },
  setBatches(state, batches) {
    state.list = batches;
  },
  setSelectedGuides(state, guides) {
    state.selectedGuides = guides;
  },
  setBatchPagination(state, pagination) {
    state.pagination = pagination;
  },
  setBatchPaginationPage(state, { page }) {
    state.pagination.page = page;
  },
};

const actions: ActionTree<BatchState, RootState> & BatchLocaleActions = {
  async getBatch({ commit, dispatch }, { _id }) {
    try {
      const response = await obmed.get<Batch>({
        entity: endpoints.byID(_id),
        config: { baseURL: VUE_APP_API_URL },
      });
      commit("setBatch", response.data);

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async getBatches({ state, commit, dispatch }) {
    const pagination: PaginationParams = {
      page: state.pagination.page,
      per_page: state.pagination.perPage,
      paginacao: true,
    };
    try {
      const response = await obmed.get<PaginationResponse<Batch>>({
        entity: endpoints.root,
        config: { query: { ...pagination }, baseURL: VUE_APP_API_URL },
      });

      commit("setBatches", response.data?.results || []);
      commit("setBatchPagination", { ...state.pagination, items: response.data.count });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async createBatch({ commit, dispatch }, { form }) {
    try {
      const response = await obmed.post<any, BatchForm>({
        entity: endpoints.root,
        data: { ...form, ie_situacao: "AB" },
        config: { baseURL: VUE_APP_API_URL },
      });

      if ((response?.status || 500) < 300)
        commit("addToast", {
          summary: "Cadastro realizado com sucesso!",
          severity: "success",
        });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async payBatch({ commit, dispatch }, { _id, form }) {
    try {
      const response = await obmed.patch<any, Partial<BatchForm>>({
        entity: endpoints.pay(_id),
        data: form,
        config: { baseURL: VUE_APP_API_URL },
      });

      if ((response?.status || 500) < 300)
        commit("addToast", {
          summary: "Lote de pagamento pago com sucesso!",
          severity: "success",
        });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async cancelBatch({ commit, dispatch }, { _id, form }) {
    try {
      const response = await obmed.patch<any, Partial<BatchForm>>({
        entity: endpoints.cancel(_id),
        data: form,
        config: { baseURL: VUE_APP_API_URL },
      });

      if ((response?.status || 500) < 300)
        commit("addToast", {
          summary: "Lote de pagamento cancelado com sucesso!",
          severity: "success",
        });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
};

const modules: ModuleTree<RootState> = {};

export const BatchStore = { state, getters, modules, mutations, actions };
