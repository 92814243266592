
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import menus from "@/router/menus";
import { UserEstablishment } from "@/store/user/types";

const CSidebar = defineComponent({
  name: "CSidebar",
  props: {
    isActive: { type: Boolean, required: true },
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const user = computed(() => store.getters.userData);
    const handledMenus = computed(() => {
      const permissions = user.value?.permissoes || [];
      if (!user.value) return [];

      return menus
        .map((menu) => {
          if (menu.items) {
            const items = menu.items.filter((submenu) => permissions.includes(String(submenu.permission)));
            menu.items = (items.length ? items : undefined) as any;

            return menu;
          }

          return menu;
        })
        .filter((menu) => menu.items || permissions.includes(String(menu.permission)));
    });
    const currentEstablishment = computed(() => store.state.user.establishment);

    const userName = computed(() => {
      const name = String(
        `${store.getters.userData?.first_name} ${store.getters.userData?.last_name}`
          .trim()
          .split(" ")
          .slice(0, 2)
          .join(" ")
      );

      if (name === "undefined") return name;
      if (name?.length > 14) return name.split(" ")[0];

      return name;
    });

    function setUserEstablishment(establishment: UserEstablishment) {
      store.commit("setUserEstablishment", establishment);
      router.go(0);
    }

    return { userName, user, currentEstablishment, handledMenus, setUserEstablishment };
  },
});

export default CSidebar;
