import obmed from "@/services/obmed";
import { ActionTree, GetterTree, ModuleTree, MutationTree } from "vuex";

import { RootState } from "@/store/types";
import {
  Permission,
  PermissionState,
  PermissionLocaleState,
  PermissionLocaleMutations,
  PermissionLocaleActions,
  PermissionLocaleGetters,
  PermissionForm,
} from "@/store/permission/types";
import { PaginationParams, PaginationResponse } from "../types";

const { VUE_APP_API_URL } = process.env;

const endpoints = {
  root: "endpoint/",
  byID: (_id: number | string) => `endpoint/${_id}/`,
};

const state: PermissionLocaleState = {
  current: null,
  list: [],
  all: [],
  pagination: { page: 1, total: 1, items: 0, perPage: 25 },
};

const getters: GetterTree<PermissionState, RootState> & PermissionLocaleGetters = {};

const mutations: MutationTree<PermissionState> & PermissionLocaleMutations = {
  setPermission(state, permission) {
    state.current = permission;
  },
  setPermissions(state, permissions) {
    state.list = permissions;
  },
  setAllPermissions(state, permissions) {
    state.all = permissions;
  },
  setPermissionPagination(state, pagination) {
    state.pagination = pagination;
  },
  setPermissionPaginationPage(state, { page }) {
    state.pagination.page = page;
  },
};

const actions: ActionTree<PermissionState, RootState> & PermissionLocaleActions = {
  async getPermission({ commit, dispatch }, { _id }) {
    try {
      const response = await obmed.get<Permission>({
        entity: endpoints.byID(_id),
        config: { baseURL: VUE_APP_API_URL, useCompany: false },
      });
      commit("setPermission", response.data);

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async getPermissions({ state, commit, dispatch }) {
    const pagination: PaginationParams = {
      page: state.pagination.page,
      per_page: state.pagination.perPage,
      paginacao: true,
    };
    try {
      const response = await obmed.get<PaginationResponse<Permission>>({
        entity: endpoints.root,
        config: { query: { ...pagination }, baseURL: VUE_APP_API_URL, useCompany: false },
      });

      commit("setPermissions", response.data?.results || []);
      commit("setPermissionPagination", { ...state.pagination, items: response.data.count });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async getAllPermissions({ commit, dispatch }) {
    try {
      const response = await obmed.get<Permission[]>({
        entity: endpoints.root,
        config: { baseURL: VUE_APP_API_URL, useCompany: false },
      });

      commit("setAllPermissions", response.data || []);

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async createPermission({ commit, dispatch }, { form }) {
    try {
      const response = await obmed.post<any, PermissionForm>({
        entity: endpoints.root,
        data: form,
        config: { baseURL: VUE_APP_API_URL, useCompany: false },
      });

      if (response?.status === 201)
        commit("addToast", {
          summary: "Permissão cadastrada com sucesso!",
          severity: "success",
        });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async updatePermission({ commit, dispatch }, { _id, form }) {
    try {
      const response = await obmed.patch<any, PermissionForm>({
        entity: endpoints.byID(_id),
        data: form,
        config: { baseURL: VUE_APP_API_URL, useCompany: false },
      });

      if (response?.status === 200)
        commit("addToast", {
          summary: "Permissão atualizada com sucesso!",
          severity: "success",
        });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async deletePermission({ commit, dispatch }, { _id }) {
    try {
      const response = await obmed.delete({
        entity: endpoints.byID(_id),
        config: { baseURL: VUE_APP_API_URL, useCompany: false },
      });

      if (response?.status === 201)
        commit("addToast", {
          summary: "Permissão removida com sucesso!",
          severity: "success",
        });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
};

const modules: ModuleTree<RootState> = {};

export const PermissionStore = { state, getters, modules, mutations, actions };
